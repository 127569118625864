import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import '../../App.css';

const CreateEntry = () => {
    const [dropdowns, setDropdowns] = useState({});
    const [dropdownsResponded, setDropdownsResponded] = useState(false);
    const params = useParams();
    const navigate = useNavigate();
    const loginID = useSelector(state => state.user._id);

    const getID = () => (params.id ? params.id : loginID);

    const [bill, setBill] = useState({
        userId: getID(),
        denomination: '1',
        sealType: '',
        grade: '',
        serialNumber: '',
        purchasePrice: 1,
        suggestedSale: 1,
        actualSale: 1,
        series: '',
        notePosition: '',
        plateSerial: '',
        backPlateSerial: '',
        fedReserveLocation: '',
        fedReserveDistrict: '',
        fw: false,
        notes: '',
        frontImage: null,
        backImage: null,
    });

    useEffect(() => {
        if (!dropdownsResponded) {
            getDropdowns();
        }
    }, []);

    const getDropdowns = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_GET_DROPDOWNS}/662ed9e118e0c1b0ead39104`);
            setDropdowns(response.data[0]);
            setDropdownsResponded(true);
        } catch (error) {
            console.error('Error while fetching dropdowns:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked, files } = e.target;
        const newValue = type === 'checkbox' ? checked : files ? files[0] : value;
        setBill({ ...bill, [name]: newValue });
    };

    const newBill = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            Object.keys(bill).forEach((key) => {
                formData.append(key, bill[key]);
            });

            await axios.post(`${process.env.REACT_APP_CREATE_BILL}`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            navigate('/home');
        } catch (error) {
            console.error('There was an error!', error);
        }
    };

    const returnOptions = (optionsKey) => {
        if (dropdownsResponded) {
            return dropdowns[optionsKey]?.map((val, i) => (
                <option key={i} value={val}>
                    {val}
                </option>
            ));
        }
        return <option disabled>Loading</option>;
    };

    const returnPCGSOptions = () => {
        const options = [];
        for (let i = 1; i < 71; i++) {
            options.push(
                <option key={i} value={i}>
                    {i}
                </option>
            );
        }
        return options;
    };

    // Styles
    const styles = {
        input: { margin: '4px 0' },
        label: { margin: '4px 0', fontWeight: 'bold' },
        centerAlign: { alignItems: 'center', textAlign: 'center' },
        submitButton: { alignSelf: 'center', textAlign: 'center', marginTop: '20px' },
    };

    return (
        <div className="container">
            <h1 className="upcase bold alignment">Create New Entry</h1>
            <form onSubmit={newBill} className="note-form" enctype="multipart/form-data">
                <h3>Note Identifiers</h3>
                <div className="flex-dir">
                    <label style={styles.label} htmlFor="denomination">
                        Denomination:
                    </label>
                    <select
                        value={bill.denomination}
                        onChange={handleInputChange}
                        style={styles.input}
                        name="denomination"
                        id="denomination"
                    >
                        <option value="">--</option>
                        {returnOptions('denominations')}
                    </select>
                </div>
                <div className="flex-dir">
                    <label style={styles.label} htmlFor="series">
                        Series:
                    </label>
                    <select
                        value={bill.series}
                        onChange={handleInputChange}
                        style={styles.input}
                        name="series"
                        id="series"
                    >
                        <option value="">--</option>
                        {returnOptions('series')}
                    </select>
                </div>
                <div className="flex-dir">
                    <label style={styles.label} htmlFor="fedReserveLocation">
                        Federal Reserve Location:
                    </label>
                    <select
                        value={bill.fedReserveLocation}
                        onChange={handleInputChange}
                        style={styles.input}
                        name="fedReserveLocation"
                        id="fedReserveLocation"
                    >
                        <option value="">--</option>
                        {returnOptions('fedReserveLocation')}
                    </select>
                </div>

                <h3>Note Artifacts</h3>
                <div className="flex-dir">
                    <label style={styles.label} htmlFor="sealType">
                        Seal Type:
                    </label>
                    <select
                        value={bill.sealType}
                        onChange={handleInputChange}
                        style={styles.input}
                        name="sealType"
                        id="sealType"
                    >
                        <option value="">--</option>
                        {returnOptions('sealTypes')}
                    </select>
                </div>
                <div className="flex-dir">
                    <label style={styles.label} htmlFor="serialNumber">
                        Serial Number:
                    </label>
                    <input
                        value={bill.serialNumber}
                        onChange={handleInputChange}
                        style={styles.input}
                        name="serialNumber"
                        type="text"
                    />
                </div>
                <div className="flex-dir">
                    <label style={styles.label} htmlFor="notePosition">
                        Note Position:
                    </label>
                    <input
                        value={bill.notePosition}
                        onChange={handleInputChange}
                        style={styles.input}
                        name="notePosition"
                        type="text"
                    />
                </div>

                <h3>Note Details</h3>
                <div className="flex-dir">
                    <label style={styles.label} htmlFor="plateSerial">
                        Front Plate:
                    </label>
                    <input
                        value={bill.plateSerial}
                        onChange={handleInputChange}
                        style={styles.input}
                        name="plateSerial"
                        type="text"
                    />
                </div>
                <div style={styles.centerAlign} className="flex-dir">
                    <label style={styles.label} htmlFor="fw">
                        FW?:
                    </label>
                    <input
                        checked={bill.fw}
                        onChange={handleInputChange}
                        style={styles.input}
                        name="fw"
                        type="checkbox"
                    />
                </div>
                <div className="flex-dir">
                    <label style={styles.label} htmlFor="backPlateSerial">
                        Back Plate:
                    </label>
                    <input
                        value={bill.backPlateSerial}
                        onChange={handleInputChange}
                        style={styles.input}
                        name="backPlateSerial"
                        type="text"
                    />
                </div>

                <h3>Note Providence</h3>
                <div className="flex-dir">
                    <label style={styles.label} htmlFor="grade">
                        PCGS Grade:
                    </label>
                    <select
                        value={bill.grade}
                        onChange={handleInputChange}
                        style={styles.input}
                        name="grade"
                        id="grade"
                    >
                        <option value="Not Graded">Not Graded</option>
                        {returnPCGSOptions()}
                    </select>
                </div>
                <div className="flex-dir">
                    <label style={styles.label} htmlFor="purchasePrice">
                        Purchase Price:
                    </label>
                    <input
                        value={bill.purchasePrice}
                        onChange={handleInputChange}
                        style={styles.input}
                        name="purchasePrice"
                        type="number"
                    />
                </div>
                <div className="flex-dir">
                    <label style={styles.label} htmlFor="suggestedSale">
                        Suggested Sale Price:
                    </label>
                    <input
                        value={bill.suggestedSale}
                        onChange={handleInputChange}
                        style={styles.input}
                        name="suggestedSale"
                        type="number"
                    />
                </div>
                <div className="flex-dir">
                    <label style={styles.label} htmlFor="actualSale">
                        Actual Sale Price:
                    </label>
                    <input
                        value={bill.actualSale}
                        onChange={handleInputChange}
                        style={styles.input}
                        name="actualSale"
                        type="number"
                    />
                </div>

                <h3>Upload Images</h3>
                <div className="flex-dir">
                    <label style={styles.label} htmlFor="frontImage">
                        Front Image:
                    </label>
                    <input
                        type="file"
                        name="frontImage"
                        accept="image/*"
                        onChange={handleInputChange}
                        style={styles.input}
                    />
                </div>
                <div className="flex-dir">
                    <label style={styles.label} htmlFor="backImage">
                        Back Image:
                    </label>
                    <input
                        type="file"
                        name="backImage"
                        accept="image/*"
                        onChange={handleInputChange}
                        style={styles.input}
                    />
                </div>

                <div style={styles.submitButton}>
                    <button style={styles.submitButton} className="button lrg primary-bgcolor" type="submit">
                        Create Entry
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CreateEntry;
