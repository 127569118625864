import React, {Component, useEffect, useState, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import '../../App.css';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

const SingleNote = props => {
    const [response, setResponse] = useState({});
    const [collection, setCollection] = useState({});
    const billId = useParams();  
    const navigate = useNavigate();
    const userFName = useSelector(state => state.user.fname);
    const userLName = useSelector(state => state.user.lname);
    const active = useSelector(state => state.user.isActive);
    const [responded, setResponded] = useState(false);

    useEffect(() => {
        if(!responded) {
            getBill(billId.id);
        }
        returnCreate();
    }, [billId.id]);

    console.log("collection: " + JSON.stringify(collection, null, 2));

    // Styles
    const alignImages = {
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%',
        flexDirection: 'column'
    }
    const tdStyle = {
        border: 'none',
        width: '300px',
        color: '#726658',
        margin: '0px 0px 48px 0px'
    };

    const container = {
        width: '100%',
        padding: '12px 48px',
        display: 'flex',
        flexDirection: 'column'
    };

    const rightAlign = {
        textAlign: 'right',
        color: '#73A478'
    };

    const leftAlign = {
        textAlign: 'left',
        fontWeight: 'bold',
        color: '#6C5F53',
    };

    const getBill = async (id) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_GET_BILL}/${id}`);
            setCollection(response.data);
            setResponded(true);
        } catch (error) {
            console.error('Error while fetching collection:', error);
        }
    };

    function deleteBill(id) {
        window.confirm("Do you wish to delete this bill?");
        axios.delete(`${process.env.REACT_APP_DELETE_BILL}/${id}`)
            .then(response => 
                console.log("Delete Successful: " + response.status))
            .catch(error => {
                console.log("Delete Failed: " + error);
                alert("Failed To Delete Bill.");
            });
        navigate('/home');
    }

    function updateBill(id) {
        navigate(`/editEntry/${id}`);
    }

    function checkVal(val) {
        if(val === "") {
            return "-";
        } else {
            return val;
        }
    }

    function checkTrue(item) {
        return item === true ? "Yes" : "No";
    }

    function returnBody() {
        if (collection.length > 0) {
            return (
                <div className="usercard" id={billId}>
                    <div>
                        <p style={rightAlign}>Denomination</p>
                        <p style={rightAlign}>Seal Type</p>
                        <p style={rightAlign}>Grade</p>
                        <p style={rightAlign}>Serial Number</p>
                        <p style={rightAlign}>Acquisition Price</p>
                        <p style={rightAlign}>Estimated Value</p>
                        <p style={rightAlign}>Actual Sale</p>
                        <p style={rightAlign}>Series</p>
                        <p style={rightAlign}>Note Position</p>
                        <p style={rightAlign}>Front Plate</p>
                        <p style={rightAlign}>Back Plate</p>
                        <p style={rightAlign}>Fed Reserve Location</p>
                        <p style={rightAlign}>FW?</p>
                        <p style={rightAlign}>Notes</p>
                        <p style={rightAlign}>Images:</p>
                    </div>
                    <div>
                        <p style={leftAlign}>${checkVal(collection[0].denomination)}</p>
                        <p style={leftAlign}>{checkVal(collection[0].sealType)}</p>
                        <p style={leftAlign}>{checkVal(collection[0].grade)}</p>
                        <p style={leftAlign}>{checkVal(collection[0].serialNumber)}</p>
                        <p style={leftAlign}>${checkVal(collection[0].purchasePrice)}</p>
                        <p style={leftAlign}>${checkVal(collection[0].suggestedSale)}</p>
                        <p style={leftAlign}>${checkVal(collection[0].actualSale)}</p>
                        <p style={leftAlign}>{checkVal(collection[0].series)}</p>
                        <p style={leftAlign}>{checkVal(collection[0].notePosition)}</p>
                        <p style={leftAlign}>{checkVal(collection[0].plateSerial)}</p>
                        <p style={leftAlign}>{checkVal(collection[0].backPlateSerial)}</p>
                        <p style={leftAlign}>{checkVal(collection[0].fedReserveLocation)}</p>
                        <p style={leftAlign}>{checkTrue(collection[0].fw)}</p>
                        <p style={leftAlign}>{checkVal(collection[0].notes)}</p>
                    </div>
                    <div></div>
                    <div style={alignImages}>
                        <img 
                            src={collection[0].frontImageUrl || 'path/to/placeholder.png'} 
                            alt="Front Image" 
                            style={{ width: '300px', height: 'auto', margin: '10px 0' }} 
                        />
                    
                        <img 
                            src={collection[0].backImageUrl || 'path/to/placeholder.png'} 
                            alt="Back Image" 
                            style={{ width: '300px', height: 'auto', margin: '10px 0' }} 
                        />
                        </div>
                    <div>
                        <button className="button medium primary-bgcolor" onClick={() => updateBill(billId.id)}>Update</button>
                        <br />
                        <button className="button medium secondary-bgcolor" onClick={() => deleteBill(billId.id)}>Delete</button>
                    </div>
                </div>
            );
        } else if (collection.length === 0) {
            return (
                <div>No Notes</div>
            );
        } else {
            return (
                <div className="loading-spinner"></div>
            );
        }
    }

    function returnCreate() {
        if(active) {
            return (
                <div className="item">
                    <Link to="/createEntry"><button className="button lrg primary-bgcolor">Create New Note</button></Link>
                </div>
            );
        } else {
            return (
                <div className="item">
                    Contact Admin to Activate You. 
                </div>
            );
        }
    }

    return (
        <div className="container">
            <div className="flex-header">
                <h1 className="item upcase bold alignment">
                    Bill Details 
                </h1>
                {returnCreate()}
            </div>
            
            <br />
            <br />     
            {returnBody()}      
        </div>
    );
};

export default SingleNote;
